import Bouncer from 'formbouncerjs';

/**
 * @param {HTMLInputElement} field
 */
function baseDateValidation(field) {
  const formgroup = /** @type {HTMLFieldSetElement} */ (field.closest('.validate-complex-date-fieldset'));
  const day = parseInt(
    field.classList.contains('validate-complex-date-day') ? field.value : /** @type {HTMLInputElement} */ (formgroup.querySelector('.validate-complex-date-day'))?.value
  );
  const month =
    parseInt(
      field.classList.contains('validate-complex-date-month') ? field.value : /** @type {HTMLInputElement} */ (formgroup.querySelector('.validate-complex-date-month'))?.value
    ) - 1;
  const year = parseInt(
    field.classList.contains('validate-complex-date-year') ? field.value : /** @type {HTMLInputElement} */ (formgroup.querySelector('.validate-complex-date-year'))?.value
  );
  return { day, month, year };
}

const BASE_CONF = {
  // Classes & IDs
  fieldClass: 'error', // Applied to fields with errors
  errorClass: 'error-message', // Applied to the error message for invalid fields
  fieldPrefix: 'bouncer-field_', // If a field doesn't have a name or ID, one is generated with this prefix
  errorPrefix: 'bouncer-error_', // Prefix used for error message IDs

  // Patterns
  // Validation patterns for specific input types
  patterns: {
    email: /^[\w.%+-]+@[\w-]+\.?[\w]+\.[\w]{2,6}$/,
    // eslint-disable-next-line max-len
    url: /^(?:https?|HTTPS?|ftp|FTP):\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[01])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4])|(?:[\dA-Za-z\u00a1-\uffff]-*)*[\dA-Za-z\u00a1-\uffff]+(?:\.(?:[\dA-Za-z\u00a1-\uffff]-*)*[\dA-Za-z\u00a1-\uffff]+)*\.[A-Za-z\u00a1-\uffff]{2,}\.?)(?::\d{2,5})?(?:[#/?]\S*)?$/,
    number: /[+-]?\d*[,.]?\d+/,
    color: /^#?([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/,
    date: /(?:19|20)\d{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2\d)|(?:(?!02)(?:0[1-9]|1[0-2])-30)|(?:(?:0[13578]|1[02])-31))/,
    time: /(0\d|1\d|2[0-3])(:[0-5]\d)/,
    month: /(?:19|20)\d{2}-(?:0[1-9]|1[0-2])/,
    name: /^.{0,50}$/
  },

  customValidations: {
    isValidPhoneLength(field) {
      if (field.name.indexOf('phone') > -1 && field.name.toLowerCase().indexOf('prefix') < 0) {
        const val = field.value;
        const reg = new RegExp(
          // eslint-disable-next-line max-len
          /^$|\d{9,}$/gim
        );
        return !reg.test(val);
      }
    },
    isValidDate(field) {
      if (field.name === 'dwfrm_profile_customer_birthday' || field.name === 'birthDate') {
        const val = field.value;
        const reg = new RegExp(
          // eslint-disable-next-line max-len
          /^((0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d)?$/gim
        );

        return !reg.test(val);
      }
    },
    isValidStateCode(field) {
      if (field.name === 'dwfrm_shipping_shippingAddress_addressFields_stateCode') {
        const val = field.value;
        const reg = new RegExp(
          // eslint-disable-next-line max-len
          /^(AG|AL|AN|AO|AQ|AR|AP|AT|AV|BA|BT|BL|BN|BG|BI|BO|BZ|BS|BR|CA|CL|CB|CI|CE|CT|CZ|CH|CO|CS|CR|KR|CN|EN|FM|FE|FI|FG|FC|FR|GE|GO|GR|IM|IS|SP|LT|LE|LC|LI|LO|LU|MC|MN|MS|MT|VS|ME|MI|MO|MB|NA|NO|NU|OG|OT|OR|PD|PA|PR|PV|PG|PU|PE|PC|PI|PT|PN|PZ|PO|RG|RA|RC|RE|RI|RM|RN|RO|SA|SS|SV|SI|SR|SO|TA|TE|TR|TO|TP|TN|TV|TS|UD|VA|VE|VB|VC|VR|VV|VI|VT)$/gim
        );

        return !reg.test(val);
      }
    },
    isValidStateCodeBilling(field) {
      if (field.name === 'dwfrm_billing_stateCode') {
        const val = field.value;
        const reg = new RegExp(
          // eslint-disable-next-line max-len
          /^(AG|AL|AN|AO|AQ|AR|AP|AT|AV|BA|BT|BL|BN|BG|BI|BO|BZ|BS|BR|CA|CL|CB|CI|CE|CT|CZ|CH|CO|CS|CR|KR|CN|EN|FM|FE|FI|FG|FC|FR|GE|GO|GR|IM|IS|SP|LT|LE|LC|LI|LO|LU|MC|MN|MS|MT|VS|ME|MI|MO|MB|NA|NO|NU|OG|OT|OR|PD|PA|PR|PV|PG|PU|PE|PC|PI|PT|PN|PZ|PO|RG|RA|RC|RE|RI|RM|RN|RO|SA|SS|SV|SI|SR|SO|TA|TE|TR|TO|TP|TN|TV|TS|UD|VA|VE|VB|VC|VR|VV|VI|VT)$/gim
        );

        return !reg.test(val);
      }
    },
    isValidPartitaIva(field) {
      if (field.name === 'dwfrm_billing_partitaIva' && field.getAttribute('data-option-skip-validation') !== '1') {
        const val = field.value;
        const reg = new RegExp(/^(?:[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]|[0-9]{11})$/g);

        return !reg.test(val);
      }
    },
    isValidCf(field) {
      if (field.name === 'dwfrm_billing_codiceFiscale') {
        const val = field.value;
        const reg =
          field.getAttribute('data-option-non-private-cf') !== '1'
            ? new RegExp(/^([A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z])?$/g)
            : new RegExp(/^(?:[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]|[0-9]{11})$/g);

        return !reg.test(val);
      }
    },
    isValidCodiceDestinatario(field) {
      if (field.name === 'dwfrm_billing_codiceDestinatario' && field.getAttribute('data-option-skip-validation') !== '1') {
        const val = field.value;
        const reg = new RegExp(/^(.{7})$/g);

        return val && val !== '' && !reg.test(val);
      }
    },
    /**
     * @param {HTMLInputElement} field
     */
    isValidDateComplexDay(field) {
      if (field.classList.contains('validate-complex-date-input')) {
        const { day, month, year } = baseDateValidation(field);
        // Check date after required check
        if (day == null || isNaN(day) || month == null || isNaN(month) || year == null || isNaN(year)) {
          return false;
        }
        const date = new Date(year, month, day);
        return !(date.getDate() == day && date.getMonth() == month && date.getFullYear() == year && year > 1000);
      }
    },
    isValidMinAge(field) {
      if (field.classList.contains('min-age-validate')) {
        const val = field.value;
        const parseDate = val.split('/');
        const birthday = new Date(parseDate[2], parseDate[1] - 1, parseDate[0]);
        const today = Date.now();
        return today - birthday.getTime() < field.getAttribute('data-option-min-age') * 31556952000;
      }
    },
    isValidPartitaIvaOrIsValidCf(field) {
      if (field.classList.contains('js-fiscal-code-p-iva')) {
        const val = field.value;
        const patternCF = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
        const patternIVA = /^[0-9]{11}$/;

        const CF = patternCF.test(val);
        const IVA = patternIVA.test(val);
        return !(CF || IVA);
      }
    },
    // Email validation for input of type text
    isValidEmail(field) {
      if ((field.name === 'email' || field.name === 'loginEmail') && field.type == 'text') {
        const val = field.value;
        const reg = new RegExp(BASE_CONF.patterns.email);

        return !reg.test(val);
      }
    },
    isConfirmedPhone(field) {
      if (field.classList.contains('confirm-phone')) {
        const val = document.querySelector('#phoneConfirm').value;
        const originalVal = document.querySelector('#phone').value;
        const prefix = document.querySelector('#phonePrefixConfirm').value;
        const originalPrefix = document.querySelector('#phonePrefix').value;
        const validnumber = val == originalVal && prefix == originalPrefix;
        return val ? !validnumber : false;
      }
    },
    isConfirmedEmail(field) {
      if (field.classList.contains('js-check-reference-email')) {
        let isEmailConfirmError = true;
        const val = document.querySelector('#registration-form-check-email').value;
        const originalVal = document.querySelector('#registration-form-email').value;
        if (val && originalVal && val === originalVal) isEmailConfirmError = false;
        return isEmailConfirmError;
      }
    },
    isValidMultiSearchPhrase(field) {
      if (field.name === 'multiSearchPhrase') {
        const maxKeywords = field.title;
        const CATEGORY_SPLIT_CHARACTER = /[\n,]+/;
        var keywords = field.value.split(CATEGORY_SPLIT_CHARACTER);
        var results = [];

        keywords.forEach(function(element) {
          var categoryName = element.trim();
          if (categoryName !== '') {
            results.push(categoryName);
          }
        });

        if (results.length > maxKeywords) {
          return true;
        }
        return false;
      }
    },
    isValidFirstName(field) {
      if (field.name === 'dwfrm_billing_firstName') {
        const val = field.value;
        const reg = new RegExp(BASE_CONF.patterns.name);

        return !reg.test(val);
      }
    },
    isValidLastName(field) {
      if (field.name === 'dwfrm_billing_lastName') {
        const val = field.value;
        const reg = new RegExp(BASE_CONF.patterns.name);

        return !reg.test(val);
      }
    }
  },

  // Message Settings
  messageAfterField: false, // If true, displays error message below field. If false, displays it above.
  messageCustom: 'data-bouncer-message', // The data attribute to use for customer error messages
  messageTarget: 'data-bouncer-target', // The data attribute to pass in a custom selector for the field error location

  // Error messages by error type
  messages: {
    ...window.formsErrorsMessages,
    isValidMinAge: function(field) {
      if (field.classList.contains('payback-birthday-check')) {
        return 'Per registrarsi è necessario aver compiuto 18 anni';
      } else {
        return "L'agevolazione di consegna è rivolta solo ad utenti di " + field.getAttribute('data-option-min-age') + ' anni compiuti ';
      }
    },
    isConfirmedPhone: 'Il primo numero indicato non è uguale al secondo',
    isConfirmedEmail: 'Accertati che la tua mail sia inserita correttamente',
    isValidPhoneLength: 'Il numero di telefono deve avere almeno 9 cifre',
    outOfRange: {
      over: 'Please select a value that is no more than {max}.',
      under: 'Please select a value that is no less than {min}.'
    },
    wrongLength: {
      over: 'è possibile inserire fino ad un massimo di {maxLength} caratteri.',
      under: 'Please lengthen this text to {minLength} characters or more.'
    },
    isValidPartitaIvaOrIsValidCf: 'Partita iva o codice fiscale non valido'
  },

  // Form Submission
  disableSubmit: true, // If true, native form submission is suppressed even when form validates

  // Custom Events
  emitEvents: true // If true, emits custom events
};

export const validateForm = () => {
  const bouncer = new Bouncer(null, BASE_CONF);
  return /** @param {HTMLElement | HTMLFormElement | HTMLFieldSetElement} target */ (target) => {
    return /** @type {(HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement)[]} */ ([...target.querySelectorAll('input, select, textarea')])
      .filter((el) => !(el.disabled || ('readOnly' in el ? el.readOnly : false) || el.type === 'reset' || el.type === 'submit' || el.type === 'button'))
      .reduce((valid, field) => {
        var validate = bouncer.validate(field, {
          dontShowErrors: true
        });
        return valid && validate && validate.valid;
      }, true);
  };
};

export const validateAndShowErrors = () => {
  const bouncer = new Bouncer(null, BASE_CONF);
  return bouncer.validateAll;
};

export default class FormValidator {
  get BASE_CONF() {
    return BASE_CONF;
  }

  get VALIDATOR() {
    return this.validator;
  }

  get VALIDATE() {
    const validator = new Bouncer(null, this.BASE_CONF);
    return validator.validate;
  }

  constructor(selector) {
    this.selector = selector || 'form:not([data-bouncer-novalidate])';
    this.cleanFieldsError();
  }

  start() {
    this.validator = new Bouncer(this.selector, this.BASE_CONF);
    this.submitFormIfValid();
    this.checkPositionInvalidField();
  }

  submitFormIfValid() {
    document.addEventListener(
      'bouncerFormValid',
      (e) => {
        const isXHR = e.target.dataset.xhr;
        if (!isXHR) e.target.submit();
      },
      false
    );
  }

  checkPositionInvalidField() {
    document.addEventListener(
      'bouncerFormInvalid',
      (e) => {
        if (e.detail && e.detail.errors && e.detail.errors.length > 0) {
          let errorField = e.detail.errors[0];
          let header = document.querySelector('header.header');
          if (header && errorField) {
            let errorFieldPosition = errorField.getBoundingClientRect();
            let styles = window.getComputedStyle(header);
            let headerHeight =
              parseInt(styles.height, 10) +
              parseInt(styles.paddingTop, 10) +
              parseInt(styles.paddingBottom, 10) +
              parseInt(styles.marginTop, 10) +
              parseInt(styles.marginBottom, 10) +
              20;
            if (errorFieldPosition.top < headerHeight) {
              errorField.scrollIntoView({ block: 'center' });
            }
          }
        }
      },
      false
    );
  }

  cleanFieldsError() {
    document.addEventListener(
      'bouncerRemoveError',
      (event) => {
        this.removeCustomError(event);
      },
      false
    );
    // Detect show error events
    document.addEventListener(
      'bouncerShowError',
      (event) => {
        this.removeCustomError(event);
      },
      false
    );
  }

  removeCustomError(event) {
    const parent = event.target.parentElement;
    const customError = parent.querySelector('.js-error-message');
    if (customError) {
      parent.removeChild(customError);
    }
  }
}
